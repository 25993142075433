import API from '../api';

// function to get single invitation data
export const getReservationData = async (key: string) => {
  let response = await API.get(`/reservations/info/${key}`);
  return response.data;
}

// function to revoke reservation
export const deleteReservation = async (key: string) => {
  let response = await API.delete(`/reservations/revoke/${key}`);
  return response.data;
}

// function to confirm arrival
export const confirmArrival = async (key: string) => {
  let response = await API.get(`/reservations/confirm_arrival/${key}`);
  return response.data;
}