import CroatianSVG from '../../assets/icons/croatian.svg';
import EnglishSVG from '../../assets/icons/english.svg';

interface IconScheme {
  className?: string
}

const Logo = (props: IconScheme) => (
  <svg width="84" height="24" viewBox="0 0 84 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M10.5 19.64C10.5 19.82 9.42 21.41 7.86 21.41C6.51 21.41 5.04 20.36 5.04 18.41V9.8H9.57V7.97H5.04V2.99L3.06 3.23V7.97H0.66V9.8H3.06V18.86C3.06 21.86 5.16 23.24 7.5 23.24C10.02 23.21 11.49 21.17 11.49 21.17L10.5 19.64ZM26.0742 8V10.43C24.6642 8.78 22.5642 7.76 20.2242 7.76C15.9342 7.76 12.4842 11.21 12.4842 15.5C12.4842 19.76 15.9342 23.24 20.2242 23.24C22.5642 23.24 24.6642 22.19 26.0742 20.57V23H28.0542V8H26.0742ZM20.2542 21.35C17.0442 21.35 14.4642 18.77 14.4642 15.5C14.4642 12.23 17.0442 9.65 20.2542 9.65C23.4942 9.65 26.0142 12.23 26.0142 15.5C26.0142 18.77 23.4942 21.35 20.2542 21.35ZM40.2844 7.76C37.9444 7.76 35.8144 8.81 34.4044 10.46V0.679999H32.4244V23H34.4044V20.51C35.8144 22.19 37.9444 23.24 40.2844 23.24C44.5744 23.24 48.0244 19.76 48.0244 15.5C48.0244 11.21 44.5744 7.76 40.2844 7.76ZM40.2244 21.35C37.0144 21.35 34.4644 18.77 34.4644 15.5C34.4644 12.23 37.0144 9.65 40.2244 9.65C43.4344 9.65 45.9844 12.23 45.9844 15.5C45.9844 18.77 43.4344 21.35 40.2244 21.35ZM57.9586 23.24C62.2186 23.24 65.6986 19.76 65.6986 15.5C65.6986 11.21 62.2186 7.76 57.9586 7.76C53.6686 7.76 50.2186 11.21 50.2186 15.5C50.2186 19.76 53.6686 23.24 57.9586 23.24ZM57.9886 21.35C54.7786 21.35 52.2286 18.77 52.2286 15.5C52.2286 12.23 54.7786 9.62 57.9886 9.62C61.2286 9.62 63.7486 12.23 63.7486 15.5C63.7486 18.77 61.2286 21.35 57.9886 21.35ZM75.6539 23.24C79.9139 23.24 83.3939 19.76 83.3939 15.5C83.3939 11.21 79.9139 7.76 75.6539 7.76C71.3639 7.76 67.9139 11.21 67.9139 15.5C67.9139 19.76 71.3639 23.24 75.6539 23.24ZM75.6839 21.35C72.4739 21.35 69.9239 18.77 69.9239 15.5C69.9239 12.23 72.4739 9.62 75.6839 9.62C78.9239 9.62 81.4439 12.23 81.4439 15.5C81.4439 18.77 78.9239 21.35 75.6839 21.35Z" fill="white"/>
  </svg>
)

const SuccessIcon = (props: IconScheme) => (
  <svg width="100" height="100" viewBox="0 0 100 100" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M50 0C22.4308 0 0 22.4288 0 50C0 77.5712 22.4308 100 50 100C77.5692 100 100 77.5712 100 50C100 22.4288 77.5692 0 50 0ZM50 96.1538C24.5519 96.1538 3.84615 75.4481 3.84615 50C3.84615 24.5519 24.5519 3.84615 50 3.84615C75.4481 3.84615 96.1538 24.5519 96.1538 50C96.1538 75.4481 75.4481 96.1538 50 96.1538Z" fill="white"/>
    <path d="M73.5616 29.4923L44.0058 62.7423L26.2 48.498C25.3731 47.8346 24.1596 47.9711 23.4981 48.798C22.8346 49.6288 22.9693 50.8384 23.7981 51.5019L43.0289 66.8865C43.3827 67.1692 43.8077 67.3077 44.2308 67.3077C44.7616 67.3077 45.2904 67.0884 45.6692 66.6615L76.4385 32.0461C77.1442 31.2538 77.0731 30.0365 76.2789 29.3327C75.4827 28.625 74.2692 28.6961 73.5616 29.4923Z" fill="white"/>
  </svg>
);

const LogoutIcon = (props: IconScheme) => (
  <svg width="12" height="9" viewBox="0 0 12 9" className={props.className} fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M11 4.5H1M1 4.5L5 1M1 4.5L5 8" stroke="#282828" strokeLinecap="round" strokeLinejoin="round"/>
  </svg>
);

const ErrorIcon = (props: IconScheme) => (
  <svg width="100" height="100" viewBox="0 0 100 100" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M50 0C22.4308 0 0 22.4288 0 50C0 77.5712 22.4308 100 50 100C77.5692 100 100 77.5712 100 50C100 22.4288 77.5692 0 50 0ZM50 96.1538C24.5519 96.1538 3.84615 75.4481 3.84615 50C3.84615 24.5519 24.5519 3.84615 50 3.84615C75.4481 3.84615 96.1538 24.5519 96.1538 50C96.1538 75.4481 75.4481 96.1538 50 96.1538Z" fill="#D12E2E"/>
    <path d="M68.6674 31.3327C67.9154 30.5808 66.7 30.5808 65.9481 31.3327L50.0001 47.2808L34.052 31.3327C33.3001 30.5808 32.0847 30.5808 31.3327 31.3327C30.5808 32.0847 30.5808 33.3001 31.3327 34.052L47.2808 50L31.3327 65.9481C30.5808 66.7 30.5808 67.9154 31.3327 68.6674C31.7077 69.0424 32.2001 69.2308 32.6924 69.2308C33.1847 69.2308 33.677 69.0424 34.052 68.6674L50.0001 52.7193L65.9481 68.6674C66.3231 69.0424 66.8154 69.2308 67.3077 69.2308C67.8 69.2308 68.2924 69.0424 68.6674 68.6674C69.4193 67.9154 69.4193 66.7 68.6674 65.9481L52.7193 50L68.6674 34.052C69.4193 33.3001 69.4193 32.0847 68.6674 31.3327Z" fill="#D12E2E"/>
  </svg>
);

const LanguageSelectArrow = (props: IconScheme) => (
  <svg className={props.className} width="7" height="5" viewBox="0 0 7 5" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M1 1.25L3.5 3.75L6 1.25" stroke="#C2C2C2" stroke-linecap="round"/>
  </svg>
);

const English = (props: IconScheme) => (
  <img src={EnglishSVG} alt="English" loading='lazy'/>
);

const Croatian = (props: IconScheme) => (
  <img src={CroatianSVG} alt="Croatian" loading='lazy'/>
);

const LanguageIcon = (props: { language: string }) => {
  if(props.language === 'en')
    return <English />
  else if(props.language === 'hr')
    return <Croatian />
  else
    return <English />
}

const AddToCalendarIcon = (props: IconScheme) => (
  <svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clip-path="url(#clip0_817_146)">
    <path d="M21 5.83337H7.00001C5.71134 5.83337 4.66667 6.87804 4.66667 8.16671V22.1667C4.66667 23.4554 5.71134 24.5 7.00001 24.5H21C22.2887 24.5 23.3333 23.4554 23.3333 22.1667V8.16671C23.3333 6.87804 22.2887 5.83337 21 5.83337Z" stroke="#AAAAAA" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M18.6667 3.5V8.16667" stroke="#AAAAAA" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M9.33333 3.5V8.16667" stroke="#AAAAAA" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M4.66667 12.8334H23.3333" stroke="#AAAAAA" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M11.6667 18.6666H16.3333" stroke="#AAAAAA" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M14 16.3334V21" stroke="#AAAAAA" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
    </g>
    <defs>
    <clipPath id="clip0_817_146">
    <rect width="28" height="28" fill="white"/>
    </clipPath>
    </defs>
  </svg>
);

const EditIcon = (props: IconScheme) => (
  <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clip-path="url(#clip0_905_314)">
    <path d="M3 15.0002H6L13.875 7.12523C14.2728 6.7274 14.4963 6.18784 14.4963 5.62523C14.4963 5.06262 14.2728 4.52305 13.875 4.12523C13.4772 3.7274 12.9376 3.50391 12.375 3.50391C11.8124 3.50391 11.2728 3.7274 10.875 4.12523L3 12.0002V15.0002Z" stroke="#AAAAAA" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M10.125 4.875L13.125 7.875" stroke="#AAAAAA" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
    </g>
    <defs>
    <clipPath id="clip0_905_314">
    <rect width="18" height="18" fill="white"/>
    </clipPath>
    </defs>
  </svg>
);

export {
  AddToCalendarIcon,
  LanguageSelectArrow,
  LanguageIcon,
  SuccessIcon,
  LogoutIcon,
  ErrorIcon,
  EditIcon,
  Logo
}