import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Navigate, useRoutes } from 'react-router-dom';
import Loading from './components/loading';
import TableRequest from './components/request';
import Layout from './containers/layout';

function App() {

  const {i18n, ready} = useTranslation();

  // load languages
  useEffect(() => {
    if(ready && i18n.language)
      // assign lang attribute
      document.documentElement.lang = i18n.language;
  }, [ready]);
  
  let element = useRoutes([
    { 
      path: "/:code",
      element: <Layout />,
    },
    { 
      path: "/request/:code",
      element: <TableRequest />,
    },
    {
      path: "*",
      element: <Navigate to="/invalid" replace />
    }
  ]);

  // languages didn't loaded return loading
  if(!ready) return <Loading fullpage={true}/>

  return element;
}

export default App;