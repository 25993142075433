import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import { LogoutIcon, ErrorIcon } from "../../components/icons";
import Loading from "../../components/loading";
import Reservation from "../../components/reservation";
import Sidebar from "../../components/sidebar";
import { getReservationData } from "../../helpers/api/reservations";
import { ReservationData } from "../../helpers/reservationsEnum";

const Layout = () => {
  let { code } = useParams();
  let navigate = useNavigate();
  const [reservationData, setReservationData] = useState<ReservationData>({reservation: null, event_data: null});
  const [loaded, setLoaded] = useState(false);
  // translation
  const {t} = useTranslation(['general', 'buttons']);

  useEffect(() => {
    if(code && code !== "invalid") {
      (async () => {
        try {
          let data = await getReservationData(code!);
          setReservationData({...data});
          setLoaded(true);
        }
        catch {
          setReservationData({reservation: null, event_data: null});
          setLoaded(true);
        }
        
      })();
    }
    else {
      setLoaded(true);
    }
  }, [code]);

  if(!loaded)
    return <Loading fullpage={true} />

  return (
    <>
      <Sidebar />
      <div className="content container">
        {
          !code || code === "invalid" ?
            <div className="reservation__details">
              <div className="data-box center">
                <ErrorIcon />
                <h2 className="reservation__info space">{t("reservations_wrong_key_title", {ns: 'general'})}</h2>
                <h6 className='reservation__h6'>{t("reservations_wrong_key_label", {ns: 'general'})}</h6>
                <div className='button--back' onClick={() => navigate(-1)}>{t("exit", {ns: 'buttons'})}<LogoutIcon /></div>
              </div>
            </div>
          :
            <Reservation code={(reservationData.reservation !== null) ? code : null} event_data={reservationData.event_data!} reservation={reservationData.reservation!} />
        }
      </div>
    </>
  )
}

export default Layout;