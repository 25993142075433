import { FormEvent, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import { getRequestData, revokeRequest, updateRequest } from "../../helpers/api/requests";
import EventBadge from "../event-badge";
import { Counter } from "../form/counter";
import { EditIcon, ErrorIcon, LogoutIcon, SuccessIcon } from "../icons";
import Loading from "../loading";
import Prompt from "../prompt";
import Sidebar from "../sidebar";

const TableRequest = () => {
  let { code } = useParams();
  let navigate = useNavigate();
  const [loaded, setLoaded] = useState(false);
  // translation
  const {t} = useTranslation(['general', 'buttons', 'inputs', 'errors']);
  // states
  const [event, setEvent] = useState({});
  const [error, setError] = useState("");
  const [edit, setEdit] = useState(false);
  const [isPromptOpened, setPromptState] = useState(false);
  const [isRevoked, setRevoked] = useState(false);
  const [requestDate, updateRequestData] = useState({ customer_name: "Jakov Melvan", group_size: 4, note: "", age_average: 18 });

  useEffect(() => {
    if(code && code !== "invalid") {
      (async () => {
        try {
          let data = await getRequestData(code!);
          updateRequestData({ ...data.request });
          setEvent({ ...data.event_data });
          setLoaded(true);
        } catch {
          setEvent({});
          setLoaded(true);
        }
      })();
    }
    else {
      setLoaded(true);
    }
  }, [code]);

  // function to toggle edit state
  const toggleEdit = () => {
    // toggle edit state
    setEdit(edit ? false : true)
    // remove error if exist
    setError("");
  }

  // function to handle any input (name and surname in this case)
  const handleInput = (e: FormEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    let name = e.currentTarget.name;
    let value = e.currentTarget.value;
    updateRequestData((prevState) => ({...prevState, [name]: value}))
  }

  // function for persons handler +/-
  const personsHandler = (value: number)=> {
    updateRequestData((prevState) => ({...prevState, group_size: prevState.group_size + value}));
  }

  const ageAverageHandler = (value: number)=> {
    updateRequestData((prevState) => ({...prevState, age_average: prevState.age_average + value}));
  }

  // function to save changes
  const saveChanges = async () => {
    try {
      // protection
      if(!code) return;
      // make API request
      await updateRequest(code, requestDate);
      // remove error if exists
      setError("");
      // success message
      setEdit(false);
    } catch(e: any) {
      let err = t(e.response.data.error, {ns: "errors"});
      setError(err);
    }
  }

  const revoke = () => {
    setPromptState(true);
  }

  const promptAction = async (action: boolean) => {
    try {
      if(!action)
        return setPromptState(false);
      // protection
      if(!code) return;
      // delete reservation
      await revokeRequest(code);
      // display successful revoking
      setRevoked(true);
    } catch {

    }
  }

  if(!loaded)
    return <Loading fullpage={true} />

  if(!('name' in event))
    return <>
      <Sidebar />
      <div className="content container">
        <div className="reservation__details">
        <div className="data-box center">
          <ErrorIcon />
          <h2 className="reservation__info space">{t("reservations_wrong_key_title", {ns: 'general'})}</h2>
          <h6 className='reservation__h6'>{t("reservations_wrong_key_label", {ns: 'general'})}</h6>
          <div className='button--back' onClick={() => navigate(-1)}>{t("exit", {ns: 'buttons'})}<LogoutIcon /></div>
        </div>
      </div>
      </div>
    </>

  if(isRevoked)
    return <>
      <Sidebar />
      <div className="content container">
        <div className="reservation__details">
          <EventBadge event={event} isInRequest />
          <div className="data-box center status">
            <SuccessIcon />
            <h2 className="reservation__info space">{t("revoke_reservation_success", {ns: "success"})}</h2>
            <h6 className='reservation__h6'>{t("can_not_revoke_action", {ns: 'general'})}</h6>
            <div className='button--back' onClick={() => navigate(-1)}>{t("exit", {ns: 'buttons'})}<LogoutIcon /></div>
          </div>
        </div>
      </div>
    </>

  return (
    <>
      <Sidebar />
      <div className={"content container" + (edit ? "" : " inputs-disabled")}>
        <div className="grid maxw request-a-table__grid">
          <div className="request-a-table">
            <h1 className="legal-content__h1">{t("table_reservation", {ns: 'general'})}</h1>
            <div className="request__edit-button" onClick={toggleEdit}>
              <EditIcon />
              <div className="label--gray">
                {edit ? t("undo_edit_request", {ns: 'general'}) : t("edit_request", {ns: 'general'})}
              </div>
            </div>
            <EventBadge event={event} isInRequest />
            <div className="request__inputs">
              <div className="input-wrapper">
                <div className="label input__label">{t("name_label", {ns: 'inputs'})}</div>
                <input type="text" placeholder={t("name_placeholder", {ns: 'inputs'})} value={requestDate.customer_name} name="customer_name" onChange={handleInput} />
              </div>
              <div className="grid--2">
                <Counter label={t("number_of_persons_label", {ns: 'inputs'})} minValue={3} maxValue={10} value={requestDate.group_size} counterHandler={personsHandler}/>
                <Counter label={t("age_average_label", {ns: 'inputs'})} minValue={18} maxValue={65} value={requestDate.age_average} counterHandler={ageAverageHandler}/>
              </div>
              <div className="input-wrapper">
                <div className="label input__label">{t("additional_note_label", {ns: 'inputs'})}</div>
                <textarea value={requestDate.note} name="note" placeholder={t("additional_note_placeholder", {ns: 'inputs'})} onChange={handleInput}></textarea>
              </div>
            </div>
            <div className="request__buttons">
              {
                error.length > 0 && <p className="error">{ error }</p>
              }
              { edit && <div className="button" onClick={saveChanges}>{t("save", {ns: 'buttons'})}</div> }
              <div className="button danger" onClick={revoke}>{t("revoke_request", {ns: 'buttons'})}</div>
            </div>
          </div>
        </div>
        <Prompt onAction={promptAction} opened={isPromptOpened} content={t("revoke_reservation_confirm", {ns: 'general'})} decline={t("back", {ns: 'buttons'})} accept={t("revoke_r", {ns: 'buttons'})} />
      </div>
    </>
  );
}

export default TableRequest;