import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import QRCode from 'react-qr-code';
import { useNavigate } from 'react-router-dom';
import config from '../../app/config';
import { formatDate, HoursPast } from '../../helpers';
import { getCurrencyList } from '../../helpers/api/currencyList';
import { confirmArrival, deleteReservation } from '../../helpers/api/reservations';
import { EventDataDetails, ReservationDetails } from '../../helpers/reservationsEnum';
import { AddToCalendarIcon, ErrorIcon, LogoutIcon, SuccessIcon } from '../icons';
import { ReactComponent as SuccessMark } from '../../assets/icons/success.svg';
import Loading from '../loading';
import Prompt from '../prompt';

const Reservation = (props: {code: string | null | undefined, reservation: ReservationDetails, event_data: EventDataDetails}) => {
  let navigate = useNavigate();
  const qrcodeBox = useRef<HTMLDivElement>(null);
  let [isLoading, setLoading] = useState(true);
  let [isPromptOpened, setPromptState] = useState(false);
  let [isArrivalConfirmed, setArrivalConfirmed] = useState(props.reservation?.arrival_confirmed);
  let [isRevoked, setRevoked] = useState(false);
  let [currencyList, setCurrencyList] = useState<any>({});

  // translation
  const {t} = useTranslation(['general', 'buttons', 'success', 'status'])

  useEffect(() => {
    (async () => {
      setCurrencyList(await getCurrencyList());
    })();
  }, []);

  useEffect(() => {
    if(qrcodeBox.current && Object.keys(currencyList).length !== 0)
      setLoading(false);
  }, [qrcodeBox.current, currencyList]);



  if(!props.code)
    return <div className="reservation__details">
      <div className="data-box center">
        <ErrorIcon />
        <h2 className="reservation__info space">{t("reservations_wrong_key_title", {ns: 'general'})}</h2>
        <h6 className='reservation__h6'>{t("reservations_wrong_key_label", {ns: 'general'})}</h6>
        <div className='button--back' onClick={() => navigate(-1)}>{t("exit", {ns: 'buttons'})}<LogoutIcon /></div>
      </div>
    </div>

  if(new Date(props.event_data.event_end) < new Date() || props.reservation.confirmed)
    return <div className="reservation__details">
      <div className="data-box center">
        <ErrorIcon />
        <h2 className="reservation__info space">{t("link_expired", {ns: 'general'})}</h2>
        <h6 className='reservation__h6'>{t("reservation_already_confirmed", {ns: 'general'})}</h6>
        <div className='button--back' onClick={() => navigate(-1)}>{t("exit", {ns: 'buttons'})}<LogoutIcon /></div>
      </div>
    </div>

  if(isRevoked)
    return <div className="reservation__details">
      <div className="event-reminder">
        <div className="event-reminder__details">
          <div className="event-reminder__banner">
            <picture>
              <img src={`${config.assets}${props.event_data.slug}${props.event_data.banner}`} alt="" loading='lazy'/>
            </picture>
          </div>
          <div>
            <h4>{ props.event_data.name }</h4>
            <h6 className="label--gray">{ formatDate(new Date(props.event_data.event_start), 1, t) }</h6>
          </div>
        </div>
      </div>
      <div className="data-box center status">
        <SuccessIcon />
        <h2 className="reservation__info space">{t("revoke_reservation_success", {ns: "success"})}</h2>
        <h6 className='reservation__h6'>{t("can_not_revoke_action", {ns: 'general'})}</h6>
        <div className='button--back' onClick={() => navigate(-1)}>{t("exit", {ns: 'buttons'})}<LogoutIcon /></div>
      </div> { /* 2b03175d8b88b8a9 */ }
    </div>

  const revokeReservation = () => {
    setPromptState(true);
  }

  const promptAction = async (action: boolean) => {
    try {
      if(!action)
        return setPromptState(false);
      // delete reservation
      await deleteReservation(props.reservation.reservation_code);
      // display successful revoking
      setRevoked(true);
      // remove from calendar
      removeFromCalendar();
    } catch {

    }
  }

  const confirmMyArrival = async () => {
    try {
      // confirm arrival
      await confirmArrival(props.reservation.reservation_code);
      // update status to display reservation if success confirmation
      setArrivalConfirmed(true)
    } catch {

    }
  }

  // function to create .ics file for add to calendar
  const addToCalendar = () => {
    // Create the .ics URL
    let url = [
      "BEGIN:VCALENDAR",
      "VERSION:2.0",
      `X-WR-RELCALID:${props.event_data.name}${props.reservation.customer_name}${props.reservation.shortcode}`,
      "BEGIN:VEVENT",
      `DTSTART:${formatDate(new Date(props.event_data.event_start), 4)}`,
      `DTEND:${formatDate(new Date(props.event_data.event_end), 4)}`,
      `SUMMARY:${props.event_data.name}`,
      `UID:${props.event_data.name}${props.reservation.customer_name}${props.reservation.shortcode}`,
      `DESCRIPTION:${t("reservation", {ns: 'general'})}: https://reservations.taboo.hr/${props.reservation.shortcode}.\\n\\n${t("reservation_takeover_until", {ns: 'general'})} ${formatDate(new Date(props.event_data.reservation_takeover), 3)} h.`,
      `LOCATION:${props.event_data.club_name}`,
      `SEQUENCE:0`,
      "BEGIN:VALARM",
      "TRIGGER:-PT2H",
      "REPEAT:1",
      "DURATION:PT2H",
      "ACTION:DISPLAY",
      "DESCRIPTION:Reminder",
      "END:VALARM",
      "BEGIN:VALARM",
      "TRIGGER:-P1D",
      "REPEAT:1",
      "DURATION:P1D",
      "ACTION:DISPLAY",
      "DESCRIPTION:Reminder",
      "END:VALARM",
      "END:VEVENT",
      "END:VCALENDAR"
    ].join("\n");
    // download .ics
    downloadICS(url);
  }

  // function to create .ics file for add to calendar
  const removeFromCalendar = () => {
    // Create the .ics URL
    let url = [
      "BEGIN:VCALENDAR",
      "VERSION:2.0",
      `X-WR-RELCALID:${props.event_data.name}${props.reservation.customer_name}${props.reservation.shortcode}`,
      "METHOD:CANCEL",
      "BEGIN:VEVENT",
      `DTSTART:${formatDate(new Date(props.event_data.event_start), 4)}`,
      `DTEND:${formatDate(new Date(props.event_data.event_end), 4)}`,
      `SUMMARY:${props.event_data.name}`,
      `UID:${props.event_data.name}${props.reservation.customer_name}${props.reservation.shortcode}`,
      `DESCRIPTION:${t("reservation_revoked", {ns: 'general'})}`,
      `LOCATION:${props.event_data.club_name}`,
      `SEQUENCE:1`,
      `STATUS:CANCELLED`,
      "END:VEVENT",
      "END:VCALENDAR"
    ].join("\n");
    // download .ics
    downloadICS(url);
  }

  const downloadICS = (url: string) => {
    // create blob
    let blob = new Blob([url], { type: 'text/calendar;charset=utf-8' });
    // create URL from blob
    let link = window.URL.createObjectURL(blob);
    // create <a> element to trigger download
    const a = document.createElement('a');
    document.body.appendChild(a);
    a.href = link;
    a.download = `${props.event_data.name} - ${props.event_data.club_name}`;
    // trigger download
    a.click();
    // remove added <a> element
    setTimeout(() => {
      window.URL.revokeObjectURL(link);
      document.body.removeChild(a);
    }, 0);
  }

  return (
    <div className={"reservation__details" + (props.event_data.receipt_sent && !isArrivalConfirmed ? " waiting-confirmation" : "")}>
      { props.event_data.event_cancelled && <div className="event-details__cancelled">{t("event_cancelled", {ns: 'status'})}</div> }
      {
        props.event_data.receipt_sent && !isArrivalConfirmed && 
          <div className="data-box">
            <h4 style={{textAlign: 'center'}}>{t("confirm_arrival", {ns: 'general'})}</h4>
            <div className="prompt-buttons grid">
              <div className="button--invert col-6" onClick={revokeReservation}>{t("not_coming", {ns: 'buttons'})}</div>
              <div className="button col-6" onClick={confirmMyArrival}>{t("coming", {ns: 'buttons'})}</div>
            </div>
          </div>
      }
      {
        props.event_data.receipt_sent && isArrivalConfirmed && 
          <div className="data-box">
            <div className='event-details__confirmations-data'>
              <div className="event-details__confirmations-status">
                <SuccessMark />
                <div className='event-details__confirmations-text'>{t("arrival_confirmed", { ns: 'general' })}</div>
              </div>
            </div>
          </div>
      }
      <div className="data-box disable-on-waiting-confirmation">
        <div className="qrcode-wrapper" ref={qrcodeBox}>
          {
            !isLoading
            ?
            <QRCode style={{ height: "auto", maxWidth: "100%", width: "100%" }} size={qrcodeBox.current ? (qrcodeBox.current.clientWidth - 50) : 256} value={props.reservation.shortcode.toString() + "#" + props.reservation.event_id } />
            :
            <Loading />
          }
        </div>
      </div>
      <div className="data-box disable-on-waiting-confirmation">
        <div className='reservation__shortcode'>{ props.reservation.shortcode }</div>
      </div>
      <div className="event-reminder disable-on-waiting-confirmation">
        <div className="event-reminder__details">
          <div className="event-reminder__banner">
            <picture>
              <img src={`${config.assets}${props.event_data.slug}${props.event_data.banner}`} alt="" loading='lazy'/>
            </picture>
          </div>
          <div>
            <h4>{ props.event_data.name }</h4>
            <h6 className="label--gray">{ formatDate(new Date(props.event_data.event_start), 1, t) }</h6>
          </div>
        </div>
        <div className="add-to-calendar" onClick={addToCalendar}><AddToCalendarIcon /></div>
      </div>
      
      <div className="event-details grid row-30 disable-on-waiting-confirmation">
        <div className="event-details__single">
          <div className="event-details__single-name">{t("takeover_label", {ns: 'general'})}</div>
          <h5>{t("until_text", {date: formatDate(new Date(props.event_data.reservation_takeover), 3)})}</h5>
        </div>
        <div className="event-details__single">
          <div className="event-details__single-name">{t("entrance_label", {ns: 'general'})}</div>
          <h5>{ Object.keys(currencyList).length && ((parseInt(props.event_data.ticket_price) === 0) ? t("free_text", {ns: 'general'}) : props.event_data.ticket_price + " " + currencyList[props.event_data.ticket_currency].symbol) }</h5>
        </div>
        <div className="event-details__single">
          <div className="event-details__single-name">{t("min_age_label", {ns: 'general'})}</div>
          <h5>{t("min_age_text", {age: props.event_data.min_age})}</h5>
        </div>
        <div className="event-details__single">
          <div className="event-details__single-name">{t("location_label", {ns: 'general'})}</div>
          <h5>{ props.event_data.club_name }</h5>
        </div>
        {
          props.event_data.dress_code && props.event_data.dress_code.length > 0 && 
          <div className="event-details__single big">
            <div className="event-details__single-name">{t("dress_code_title")}</div>
            <h5>{props.event_data.dress_code}</h5>
          </div>
        }
        <div className="event-details__single big">
          <div className="event-details__single-name">{t("min_bottles_title")}</div>
          <h5>{t("min_bottles", { normal: props.event_data.minimum_bottles_classic, vip: props.event_data.minimum_bottles_vip })}</h5>
        </div>
      </div>
      { !props.reservation.confirmed && !props.event_data.event_cancelled && HoursPast(new Date(props.event_data.event_start), -2) && <div className="button danger" onClick={revokeReservation}>{t("revoke_reservation_title", {ns: 'general'})}</div> }
      <Prompt onAction={promptAction} opened={isPromptOpened} content={t("revoke_reservation_confirm", {ns: 'general'})} decline={t("back", {ns: 'buttons'})} accept={t("revoke_r", {ns: 'buttons'})} />
    </div>
  )
}

export default Reservation;