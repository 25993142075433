import { useTranslation } from "react-i18next";
import config from "../../app/config";
import { formatDate } from "../../helpers";

interface EventsScheme {
  id: number, 
  name: string, 
  banner:string,
  min_age: number, 
  event_start: Date, 
  event_end: Date, 
  reservation_start: Date, 
  reservation_takeover: Date, 
  ticket_price: string, 
  ticket_currency: string, 
  event_cancelled: boolean,
  minimum_bottles_vip: string,
  minimum_bottles_classic: string,
  event_mode: 'choose_section' | 'choose_table' | 'request',
  slug: string
}

interface EventBadgeSceheme {
  event: EventsScheme | {},
  isInRequest?: boolean
}

const EventBadge = (props: EventBadgeSceheme) => {
  const {t} = useTranslation(['general']);

  if(!('slug' in props.event))
    return <></>

  return (
    <div className={"event-reminder" + (props.isInRequest ? " in-request" : "")}>
      <div className="event-reminder__details">
        <div className="event-reminder__banner">
          <picture>
            <img src={`${config.assets}${props.event.slug}${props.event.banner}`} alt="" loading='lazy'/>
          </picture>
        </div>
        <div>
          <h4>{ props.event.name }</h4>
          <h6 className="label--gray">{ (new Date(props.event.event_start) > new Date()) ? formatDate(new Date(props.event.event_start), 1, t) : t("event_going_text") }</h6>
        </div>
      </div>
    </div>
  );
}

export default EventBadge;