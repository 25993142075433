import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import config from "../../app/config";
import { LanguageIcon, LanguageSelectArrow } from "../icons";

const LanguageSwitcher = (props: { isLogin?: boolean }) => {
  
  const { i18n } = useTranslation();
  const [ dropdown, setDropdown ] = useState(false);
  const ref = useRef<any>(null);

  function changeLanguage(e:any) {
    i18n.changeLanguage(e.currentTarget.dataset.value);
    // assign lang attribute
    document.documentElement.lang = i18n.language;
  }

  useEffect(() => {
    // function to be called on outside click
    function handleClickOutside(event: MouseEvent) {
      if(ref.current && !ref.current.contains(event.target)) {
        setDropdown(false);
        // Unbind the event listener
        document.removeEventListener("mousedown", handleClickOutside, false);
      }
    }
    // Bind the event listener
    dropdown && document.addEventListener("mousedown", handleClickOutside, false);
    // on component unmount
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener("mousedown", handleClickOutside, false);
    };
  }, [ref, dropdown]);
  
  return (
    <div className={'language-switcher' + (props.isLogin ? " login-language" : "")} ref={ref} onClick={() => setDropdown(dropdown ? false : true)}>
      <LanguageIcon language={i18n.language} />
      <div className="language__selected">{i18n.language}</div>
      <LanguageSelectArrow className="language-switcher__arrow" />
      <div className={"languages" + (dropdown ? " opened" : "")}>
        {
          Object.entries(config.supportedLngs).map(([short, long]) => 
            <div className={"language label" + (i18n.language === short ? " active" : "")} onClick={changeLanguage} data-value={short}><LanguageIcon language={short} /><span>{long}</span></div>
          )
        }
      </div>
    </div>
  )
}

export default LanguageSwitcher;