
import API from '../api';

// function to get single request data
export const getRequestData = async (key: string) => {
  let response = await API.get(`/requests/info/${key}`);
  return response.data;
}

// function to revoke request
export const revokeRequest = async (key: string) => {
  let response = await API.delete(`/requests/revoke/${key}`);
  return response.data;
}

// function to revoke request
export const updateRequest = async (key: string, data: { customer_name: string, group_size: number, note:string }) => {
  let response = await API.put(`/requests/update/${key}`, { query: data });
  return response.data;
}