const config = {
  api: 'https://api.taboo-host.com/', // 'https://api.taboo.hr/',
  assets: 'https://assets.taboo-host.com/', // 'https://assets.taboo.hr'
  languages: 'https://assets.taboo-host.com/languages/',
  supportedLngs: {"en": "English", "hr": "Hrvatski"}
}

// const config = {
//   api: 'http://134.209.254.57:8080/', // 'https://api.taboo.hr/',
//   assets: 'http://134.209.254.57/assets/', // 'https://assets.taboo.hr',
//   languages: 'http://localhost:3000/locales/',
//   supportedLngs: {"en": "English", "hr": "Hrvatski"}
// }


/*const config = {
  api: 'http://localhost:8080/',
  assets: 'https://assets.taboo.hr'
}
*/
export default config;