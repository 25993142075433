import { useState } from 'react';
import { Logo } from '../icons';
import LanguageSwitcher from '../language';

const Sidebar = () => {
  const [navbarOpen, setNavbarOpen] = useState(false);

  // function to toggle mobile navbar
  const toggleNavbar = () => setNavbarOpen(!navbarOpen);
  return (
    <div className={"sidebar" + (navbarOpen ? " opened" : "")}>
      <div className="sidebar__header">
        <div className="hamburger" onClick={toggleNavbar}></div>
        <div className="logo"><Logo /></div>
        <LanguageSwitcher />
      </div>
      <div className="sidebar__menu">
        <nav>
          <div className="nav__label label">Menu</div>
            <ul>
              <li>
                <a href="https://taboo.hr/">
                  Taboo.hr
                </a>
              </li>
            </ul>
        </nav>
      </div>
    </div>
  )
}

export default Sidebar;