interface PromptScheme {
  onAction: (action: boolean) => void,
  opened: boolean,
  content: string,
  decline: string,
  accept: string
}

const Prompt = (props: PromptScheme) => {
  
  return (
    <div className={"prompt-wrapper container" + (props.opened ? " opened" : "")}>
      <h2>{props.content}</h2>
      <div className="prompt-buttons grid">
        <div className="button--invert col-6" onClick={() => props.onAction(false)}>{props.decline}</div>
        <div className="button col-6" onClick={() => props.onAction(true)}>{props.accept}</div>
      </div>
    </div>
  )
}

export default Prompt;