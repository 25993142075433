const weekDays = ["sunday", "monday", "thuesday", "wednesday", "thursday", "friday", "saturday"];

// format date function
export const formatDate = (date: Date, type: number, t?: any) => {
  if(type === 1)
    return ("0" + date.getDate()).slice(-2) + "." + ("0"+(date.getMonth()+1)).slice(-2) + "." + date.getFullYear() + ", " + t!(weekDays[date.getDay()], {ns: 'general'});
  else if(type === 2)
    return date.getDate() + '.' + (date.getMonth() + 1) + '.' + date.getFullYear();
  else if(type === 3)
    return ((date.getHours() > 9) ? date.getHours() : '0' + date.getHours()) + ':' + ((date.getMinutes() > 9) ? date.getMinutes() : '0'+date.getMinutes());
  else if(type === 4) // for calendar (add to calendar)
    return date.getFullYear().toString() + ((date.getMonth() + 1)<10? "0" + (date.getMonth() + 1).toString():(date.getMonth() + 1).toString()) + ((date.getDate() + 1)<10? "0" + date.getDate().toString():date.getDate().toString()) + "T" + (date.getHours()<10 ? '0' : '') + date.getHours().toString() + date.getMinutes().toString() + "00";
  else return '';
}

// function to check if hours have passed
export const HoursPast = (date:Date, num_hours: number) : boolean => {
  return new Date(date.getTime() + 1000*60*60*num_hours) > new Date();
}

// function to move time
export const ReverseDate = (date:Date, num_hours: number) : Date => {
  return new Date(date.getTime() + 1000*60*60*num_hours);
}